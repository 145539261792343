import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/core/Typography';

import { STORAGE_KEYS } from 'constants/storageKeys';

import { setStorage, getStorage } from 'services/storage';

import pivotLogo from 'assets/images/pivot-logo.png';

import SearchInput from '../../core/SearchInput';

import './styles.scss';

const Header = ({ userInfo }) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearchValue = useCallback(value => {
    setSearchValue(value);
  }, []);

  let isFirstTimeLogin = false;

  if (userInfo?.email) {
    const userHasBeenLogin = getStorage(STORAGE_KEYS.USER_HAS_BEEN_LOGIN) || [];
    if (!userHasBeenLogin.includes(userInfo?.email)) {
      isFirstTimeLogin = true;
      setStorage({
        key: STORAGE_KEYS.USER_HAS_BEEN_LOGIN,
        val: [...userHasBeenLogin, userInfo.email],
      });
    }
  }

  const userDisplayName = userInfo?.fullName || 'Admin';
  const welcomeMessage = isFirstTimeLogin
    ? `Hello, ${userDisplayName}. Welcome to PIVOT Business Reporting Modules. This reporting tools are customized to cater for your reporting needs! Let's explore available reports via categories below or view pending action reports, in progress reports and recently published reports. Let's go!`
    : `Hello, ${userDisplayName}. It’s really good to see you again.`;

  return (
    <header className="pvda-header">
      <div className="pvda-header__logo">
        <img src={pivotLogo} alt="Pivot Logo" width={86} height={30} />
        <Typography
          component="span"
          type="body-normal-semibold"
          color="purple-p900"
        >
          {welcomeMessage}
        </Typography>
      </div>
      <div className="pvda-header__search">
        <SearchInput
          value={searchValue}
          placeholder="start your search..."
          onSetValue={onSearchValue}
        />
      </div>
    </header>
  );
};

Header.propTypes = { userInfo: PropTypes.object };

Header.defaultProps = { userInfo: {} };

export default memo(Header);
