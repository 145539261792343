/* eslint-disable camelcase */
const { config } = window;

const qs = new URLSearchParams({
  redirect_uri: `${window.location.origin}/auth/callback`,
  client_id: config?.CLIENT_ID,
  response_type: 'id_token token',
  scope: 'openid profile',
  state: '0Csr13', // A randomly generated unique value. recommended
  nonce: 'VTTaVx', // A randomly generated unique value. required
});

const REDIRECT_TO_AUTHENTICATION = `https://login.microsoftonline.com/${
  config?.TENANT_ID
}/oauth2/v2.0/authorize?${qs.toString()}`;
const REDIRECT_TO_SIGNOUT = `https://login.microsoftonline.com/${config?.TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
const HUB_NOTIFICATION_APPLICATION_URL =
  'https://staging.notificationservice.petronas.com/NotificationHub';

export default {
  BASE_URL: config?.BASE_URL,
  SOCIAL_SERVICE_URL: config?.SOCIAL_SERVICE_URL,
  SOCIAL_SERVICE_SOCKET_URL: config?.SOCIAL_SERVICE_SOCKET_URL,
  APPLICATION_ID: config?.APPLICATION_ID,
  REDIRECT_TO_AUTHENTICATION,
  REDIRECT_TO_SIGNOUT,
  HUB_NOTIFICATION_APPLICATION_URL,
  GA_ID: window.config?.GA_ID || 'GTM-KQHXJ7B8',
  ELASTIC_APM_ADO_ID:
    window.config?.ELASTIC_APM_ADO_ID || '04A26F49-82E9-4DC8-BB4A-3C30C7C59DD7',
  ELASTIC_APM_SERVER_URL:
    window.config?.ELASTIC_APM_SERVER_URL || 'https://prd.apm.petronas.com',
};
