import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import settings from 'settings';

import { loadScriptGA } from 'services/helpers/googleTagEvents';

import App from './app';

import configureAppStore from './store/configureAppStore';

import registerServiceWorker from './registerServiceWorker';

import 'services/apm';

const container = document.getElementById('root');
const store = configureAppStore();

const { GA_ID } = window?.config || settings;
loadScriptGA(GA_ID);

render(
  <Provider store={store}>
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  </Provider>,
  container,
);

registerServiceWorker();
