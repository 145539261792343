/* eslint-disable camelcase */
const config = {
  BASE_URL: 'https://drrv2yn1l27j9.cloudfront.net/api',
  TENANT_ID: 'fa6434db-4921-4ae3-8a0c-60977ad6c33c',
  CLIENT_ID: '943cd74d-f2df-4130-8c5f-3984512096fb',
  CLIENT_SECRET: '2n0K-GP.ekjXU4~de~5iFube0e42bg__fJ',
  SOCIAL_SERVICE_URL:
    'https://oakseag2mj.execute-api.ap-southeast-1.amazonaws.com/dev/api',
  SOCIAL_SERVICE_SOCKET_URL:
    'wss://zsxkbubmyj.execute-api.ap-southeast-1.amazonaws.com/dev',
  APPLICATION_ID: '943cd74d-f2df-4130-8c5f-3984512096fb',
  FILE_SERVICE_URL:
    'https://preprodfileservice.petronas.com/api/v1/application/upload/file',
  FS_FOLDER_ID: 'a7dbf767-1eb4-4cba-989d-653f9cfacb0f',
  FS_APPLICATION_ID: '36be03f1-ba6f-49a9-99cd-9460388de153',
};
const qs = new URLSearchParams({
  redirect_uri: `${window.location.origin}/auth/callback`,
  client_id: config?.CLIENT_ID,
  response_type: 'id_token token',
  scope: 'openid profile',
  state: '0Csr13', // A randomly generated unique value. recommended
  nonce: 'VTTaVx', // A randomly generated unique value. required
});

const REDIRECT_TO_AUTHENTICATION = `https://login.microsoftonline.com/${
  config?.TENANT_ID
}/oauth2/v2.0/authorize?${qs.toString()}`;
const REDIRECT_TO_SIGNOUT = `https://login.microsoftonline.com/${config?.TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
const HUB_NOTIFICATION_APPLICATION_URL =
  'https://staging.notificationservice.petronas.com/NotificationHub';

export default {
  BASE_URL: config?.BASE_URL,
  SOCIAL_SERVICE_URL: config?.SOCIAL_SERVICE_URL,
  SOCIAL_SERVICE_SOCKET_URL: config?.SOCIAL_SERVICE_SOCKET_URL,
  APPLICATION_ID: config?.APPLICATION_ID,
  REDIRECT_TO_AUTHENTICATION,
  REDIRECT_TO_SIGNOUT,
  HUB_NOTIFICATION_APPLICATION_URL,
  GA_ID: window.config?.GA_ID || 'GTM-TVHCVRGS',
  ELASTIC_APM_ADO_ID:
    window.config?.ELASTIC_APM_ADO_ID || '04A26F49-82E9-4DC8-BB4A-3C30C7C59DD7',
  ELASTIC_APM_SERVER_URL:
    window.config?.ELASTIC_APM_SERVER_URL || 'https://prd.apm.petronas.com',
};
