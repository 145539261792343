import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API_STATUS } from 'constants/apis';
import { STORAGE_KEYS } from 'constants/storageKeys';
import { getStorage } from 'services/storage';

export const ROOT_STATE_NAME = 'user';
const initialState = {
  userInfo: {
    data: null,
    status: API_STATUS.LOADING, // or: API_STATUS.LOADING, API_STATUS.SUCCEEDED, API_STATUS.FAILED
    error: null,
  },
  usersUAM: {
    data: [],
    status: API_STATUS.IDLE,
    error: null,
  },
  usersAvatar: {},
  isAuthenticated:
    !!getStorage(STORAGE_KEYS.ID_TOKEN) &&
    !!getStorage(STORAGE_KEYS.ACCESS_TOKEN),
  isShowLeftBar: false,
  isShowRightBar: false,
};

const userSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getUserInfoRequest(state) {
      state.userInfo.status = API_STATUS.LOADING;
    },
    getUserInfoSuccess(state, action) {
      state.userInfo.data = action.payload;
      state.userInfo.status = API_STATUS.SUCCEEDED;
    },
    getUserInfoFailure(state, action) {
      state.userInfo.status = API_STATUS.FAILED;
      state.userInfo.data = {};
      state.userInfo.error = action.payload;
    },
    updateIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    updateIsShowLeftBar(state, action) {
      state.isShowLeftBar = action.payload;
    },
    updateIsShowRightBar(state, action) {
      state.isShowRightBar = action.payload;
    },
    getUsersUAMRequest(state) {
      state.usersUAM.status = API_STATUS.LOADING;
      state.usersUAM.data = [];
    },
    getUsersUAMSuccess(state, action) {
      state.usersUAM.data = action.payload;
      state.usersUAM.status = API_STATUS.SUCCEEDED;
      state.usersUAM.error = null;
    },
    getUsersUAMFailure(state, action) {
      state.usersUAM.status = API_STATUS.FAILED;
      state.usersUAM.data = [];
      state.usersUAM.error = action.payload;
    },
    getCollaboratorsUAMRequest(state) {
      state.usersUAM.status = API_STATUS.LOADING;
      state.usersUAM.data = [];
    },
    getCollaboratorsUAMSuccess(state, action) {
      state.usersUAM.data = action.payload;
      state.usersUAM.status = API_STATUS.SUCCEEDED;
      state.usersUAM.error = null;
    },
    getCollaboratorsUAMFailure(state, action) {
      state.usersUAM.status = API_STATUS.FAILED;
      state.usersUAM.data = [];
      state.usersUAM.error = action.payload;
    },
    addUsersAvatar(state, action) {
      state.usersAvatar[action.payload.key] = action.payload.data;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  updateIsAuthenticated,
  updateIsShowLeftBar,
  updateIsShowRightBar,
  getUsersUAMRequest,
  getUsersUAMSuccess,
  getUsersUAMFailure,
  getCollaboratorsUAMRequest,
  getCollaboratorsUAMSuccess,
  getCollaboratorsUAMFailure,
  addUsersAvatar,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const userInfoSelector = createSelector(
  rootSelector,
  ({ userInfo }) => userInfo,
);
export const isAuthenticatedSelector = createSelector(
  rootSelector,
  ({ isAuthenticated }) => isAuthenticated,
);
export const isShowLeftBarSelector = createSelector(
  rootSelector,
  ({ isShowLeftBar }) => isShowLeftBar,
);
export const isShowRightBarSelector = createSelector(
  rootSelector,
  ({ isShowRightBar }) => isShowRightBar,
);
export const usersUAMSelector = createSelector(
  rootSelector,
  ({ usersUAM }) => usersUAM,
);
export const usersAvatarSelector = createSelector(
  rootSelector,
  ({ usersAvatar }) => usersAvatar,
);
