/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Routes as Switch,
  Navigate,
  Route,
  useLocation,
} from 'react-router-dom';

import ProgressBar from 'react-bootstrap/ProgressBar';

const Routes = ({ routes, isAuthenticated }) => {
  const { pathname, search } = useLocation();

  return (
    <Suspense
      fallback={
        <ProgressBar className="pvda-b-progressbar pvda-b-progressbar--fixed" />
      }
    >
      <Switch>
        {routes.map(route => {
          if (route?.requiredAuthen) {
            return (
              <Route
                key={route?.path}
                path={route?.path}
                exact={route?.exact}
                element={
                  isAuthenticated ? (
                    <route.component location={location} />
                  ) : (
                    <Navigate
                      to="/auth"
                      state={{ from: { pathname: `${pathname}${search}` } }}
                    />
                  )
                }
              />
            );
          }

          return (
            <Route
              key={route?.path}
              path={route?.path}
              exact={route?.exact}
              element={<route.component location={location} />}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

Routes.propTypes = {
  routes: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

Routes.defaultProps = {
  routes: [],
  isAuthenticated: false,
};

export default memo(Routes);
