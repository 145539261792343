import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { STORAGE_KEYS } from 'constants/storageKeys';
import { CALLBACK_AUTH_PATH } from 'constants/routePaths';
import { setStorage, getStorage, removeStorage } from 'services/storage';
import { onSignIn, onSignOut } from 'settings/auth-utils';

import {
  isAuthenticatedSelector,
  userInfoSelector,
  updateIsAuthenticated,
  getUserInfoRequest,
} from '../slices/user';

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userInfo = useSelector(userInfoSelector);

  const dispatch = useDispatch();

  const onUpdateIsAuthenticated = useCallback(value => {
    dispatch(updateIsAuthenticated(value));
  }, []);

  const onGetUserInfo = useCallback(params => {
    dispatch(getUserInfoRequest(params));
  }, []);

  const token = useMemo(
    () => getStorage(STORAGE_KEYS.ID_TOKEN),
    [isAuthenticated],
  );

  const onLoginWithRedirect = useCallback(
    (isTokenExpired = false) => {
      let pathname = '';
      if (isTokenExpired) {
        pathname = `${window?.location?.pathname}${window?.location?.search}`;
      } else if (location?.state?.from?.pathname) {
        pathname = location.state.from.pathname;
      } else {
        pathname = '/';
      }

      if (token && !isTokenExpired) {
        onUpdateIsAuthenticated(true);
        navigate(pathname);
      } else {
        setStorage({
          key: STORAGE_KEYS.CURRENT_PATH,
          val: pathname,
        });
        removeStorage(STORAGE_KEYS.ACCESS_TOKEN);
        removeStorage(STORAGE_KEYS.ID_TOKEN);
        onUpdateIsAuthenticated(false);
        onSignIn();
      }
    },
    [token, location?.state?.from?.pathname],
  );

  const onLogoutWithRedirect = useCallback(() => {
    removeStorage(STORAGE_KEYS.CURRENT_PATH);
    removeStorage(STORAGE_KEYS.ACCESS_TOKEN);
    removeStorage(STORAGE_KEYS.ID_TOKEN);
    onSignOut();
  }, []);

  const onReceiveAuthCallback = useCallback(() => {
    const currentPath = getStorage(STORAGE_KEYS.CURRENT_PATH);
    const redirectPath =
      !currentPath || currentPath === CALLBACK_AUTH_PATH ? '/' : currentPath;

    if (isAuthenticated) {
      navigate(redirectPath);
    }
  }, [isAuthenticated]);

  return {
    token,
    isAuthenticated,
    userInfo,
    onLoginWithRedirect,
    onLogoutWithRedirect,
    onReceiveAuthCallback,
    onGetUserInfo,
  };
};

export default useAuth;
