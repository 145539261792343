import isEmpty from 'lodash/isEmpty';

export const sendUserID = uid => {
  if (!isEmpty(window.dataLayer)) {
    window.dataLayer.push({
      event: 'SEND_USER_ID',
      uid,
    });
  }
};

export const loadScriptGA = id => {
  if (id) {
    // Adding the script tag to the head as suggested before
    const { head, body } = document;

    const script = document.createElement('script');
    script.innerHTML = `
        (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${id}');
      `;

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>`;
    // Fire the loading
    head.appendChild(script);
    body.appendChild(noscript);
  }
};
