import React, { useEffect, useCallback, useMemo, useState, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import axios from 'services/api';

import { API_STATUS } from 'constants/apis';

import useAuth from 'hooks/useAuth';
import useAxiosInterceptor from 'hooks/useAxiosInterceptor';
import useSocialService from 'hooks/useSocialService';

import { sendUserID } from 'services/helpers/googleTagEvents';

import GlobalLoading from 'components/core/GlobalLoading';
import ScrollToTop from 'components/core/ScrollToTop';
import Main from 'components/layout/Main/index';

import ToastContext from 'context/ToastContext';

import withAuthProvider from 'hocs/withAuthProvider';

import { updateIsAuthenticated } from 'slices/user';

import './global-styles.scss';

const App = ({ silentSignedIn, account }) => {
  const dispatch = useDispatch();

  const [toast, setToast] = useState({
    show: false,
    title: '',
    description: '',
  });

  const onShowToast = useCallback(({ title, description }) => {
    setToast({
      show: true,
      title,
      description,
    });
  }, []);

  const onHideToast = useCallback(() => {
    setToast({
      show: false,
      title: '',
      description: '',
    });
  }, []);

  const toastValue = useMemo(
    () => ({
      toast,
      onShowToast,
      onHideToast,
    }),
    [toast, onShowToast, onHideToast],
  );

  useAxiosInterceptor(axios);
  const { isAuthenticated, userInfo, onGetUserInfo } = useAuth();
  useSocialService(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      onGetUserInfo();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (account && !isAuthenticated && silentSignedIn) {
      dispatch(updateIsAuthenticated(true));
    }
  }, [account, isAuthenticated, silentSignedIn]);

  useEffect(() => {
    if (!isEmpty(userInfo?.data)) {
      const uid = userInfo?.data?.userName || userInfo?.data?.email;
      sendUserID(uid);
    }
  }, [userInfo]);

  return (
    <ToastContext.Provider value={toastValue}>
      {userInfo?.status === API_STATUS.LOADING && <GlobalLoading />}
      <ScrollToTop />
      <Main isAuthenticated={isAuthenticated} userInfo={userInfo} />
    </ToastContext.Provider>
  );
};

App.propTypes = {
  silentSignedIn: PropTypes.bool,
  account: PropTypes.any,
};

App.defaultProps = {
  silentSignedIn: false,
  account: null,
};

export default memo(withAuthProvider(App));
