/* eslint-disable no-console */
import { PublicClientApplication, Logger, LogLevel } from '@azure/msal-browser';

export const serviceConfig = {
  tenantId: window?.config?.TENANT_ID,
  clientId: window?.config?.CLIENT_ID,
  redirectUri: `${window.location.origin}/auth/callback`,
  logoutRedirectUri: `${window.location.origin}/`,
};

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const REQUESTS_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  USER_READ_ALL: 'User.Read.All',
  Directory_READ: 'User.Read',
  Directory_READ_ALL: 'User.Read.All',
  MAIL_READ: 'Mail.Read',
  CLIENT_ID: `${serviceConfig.clientId}/.default`,
};

export const REQUESTS = {
  LOGIN: {
    scopes: [
      REQUESTS_SCOPES.OPENID,
      REQUESTS_SCOPES.PROFILE,
      REQUESTS_SCOPES.USER_READ,
      REQUESTS_SCOPES.USER_READ_ALL,
      REQUESTS_SCOPES.Directory_READ_ALL,
    ],
  },
  ACQUIRE_TOKEN: {
    scopes: [REQUESTS_SCOPES.CLIENT_ID],
  },
};

export const msalApp =
  await PublicClientApplication.createPublicClientApplication({
    auth: {
      clientId: serviceConfig.clientId,
      authority: `https://login.microsoftonline.com/${serviceConfig.tenantId}`,
      redirectUri: serviceConfig.redirectUri,
      validateAuthority: true,
      postLogoutRedirectUri: serviceConfig.logoutRedirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: isIE(),
    },
    system: {
      navigateFrameWait: 50000,
      logger: new Logger(
        (logLevel, message) => {
          console.log('message', message);
        },
        {
          level: LogLevel.Verbose,
          piiLoggingEnabled: true,
        },
      ),
    },
  });

export const onSignIn = async () => {
  msalApp.loginRedirect({
    ...REQUESTS.LOGIN,
    redirectUri: serviceConfig.redirectUri,
  });
};

export const onSignOut = async () => {
  msalApp.logout();
};
