import { init as initApm } from '@elastic/apm-rum';

import settings from 'settings';

let envInfor = {};
const nodeEnv = window?.config?.NODE_ENV || settings?.NODE_ENV;

switch (nodeEnv) {
  case 'Production':
    envInfor = {
      environment: 'Production',
      urlBE: window?.config?.BASE_URL || settings?.BASE_URL,
    };
    break;
  case 'Staging':
    envInfor = {
      environment: 'Staging',
      urlBE: window?.config?.BASE_URL || settings?.BASE_URL,
    };
    break;
  case 'QA':
    envInfor = {
      environment: 'UAT',
      urlBE: window?.config?.BASE_URL || settings?.BASE_URL,
    };
    break;
  default:
    envInfor = {
      environment: 'Development',
      urlBE: window?.config?.BASE_URL || settings?.BASE_URL, 
    };
    break;
}

const apm = initApm({
  serviceName: 'PIVOT-MMRv2-FE-SERVICE',
  serverUrl:
    window?.config?.ELASTIC_APM_SERVER_URL || settings?.ELASTIC_APM_SERVER_URL,
  environment: envInfor.environment,
  distributedTracingOrigins: [envInfor.urlBE],
  propagateTracestate: true,
});

apm.addLabels({
  adoid: window?.config?.ELASTIC_APM_ADO_ID || settings?.ELASTIC_APM_ADO_ID,
  pn: 'PIVOT-MMRv2',
  bs: 'downstream',
});

export default apm;
