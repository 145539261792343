/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  msalApp,
  requiresInteraction,
  serviceConfig,
  REQUESTS,
} from 'settings/auth-utils';
import { setStorage, getStorage } from 'services/storage';

import { STORAGE_KEYS } from 'constants/storageKeys';

import { parseJwt } from 'services/format/uri';

const useRedirectFlow = true;

const withAuthProvider = PassedComponent => {
  const WrappedComponent = wrappedProps => {
    const [state, setState] = useState({
      account: null,
      error: null,
      silentSignedIn: null,
      interval: null,
      isLogin: null,
    });

    async function acquireToken(request, redirect) {
      return msalApp.acquireTokenSilent(request).catch(error => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
          return redirect
            ? msalApp.acquireTokenRedirect({
                ...request,
                redirectUri: serviceConfig.redirectUri,
              })
            : msalApp.acquireTokenPopup(request);
        }

        console.error('Non-interactive error:', error);
        return null;
      });
    }

    useEffect(async () => {
      try {
        const graphToken = await msalApp.handleRedirectPromise();

        if (graphToken) {
          const appToken = await acquireToken(
            { account: graphToken.account, ...REQUESTS.ACQUIRE_TOKEN },
            useRedirectFlow,
          );

          setStorage({ key: STORAGE_KEYS.ID_TOKEN, val: appToken.accessToken });
          setStorage({
            key: STORAGE_KEYS.ACCESS_TOKEN,
            val: graphToken.accessToken,
          });
          setStorage({
            key: STORAGE_KEYS.ACCOUNT,
            val: JSON.stringify(graphToken.account),
          });

          setState({
            ...state,
            account: graphToken.account,
            error: null,
            isLogin: true,
          });
        } else {
          const account = msalApp.getAllAccounts();

          if (account.length > 0) {
            const acquireGraphToken = await acquireToken(
              { account: account[0], ...REQUESTS.LOGIN },
              useRedirectFlow,
            );
            const appToken = await acquireToken(
              { account: account[0], ...REQUESTS.ACQUIRE_TOKEN },
              useRedirectFlow,
            );

            if (acquireGraphToken && appToken) {
              setStorage({
                key: STORAGE_KEYS.ID_TOKEN,
                val: appToken.accessToken,
              });
              setStorage({
                key: STORAGE_KEYS.ACCESS_TOKEN,
                val: acquireGraphToken.accessToken,
              });
              setStorage({
                key: STORAGE_KEYS.ACCOUNT,
                val: JSON.stringify(account[0]),
              });

              setState({
                ...state,
                account: account[0],
                error: null,
                isLogin: true,
              });
            }
          }
        }
      } catch (error) {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : 'Unable to acquire access token.';
          setState({
            ...state,
            error: errorMessage,
          });
        }
      }

      const interval = setInterval(async () => {
        try {
          const token = getStorage(STORAGE_KEYS.ID_TOKEN);
          const account = JSON.parse(getStorage(STORAGE_KEYS.ACCOUNT));
          const jwtParsed = parseJwt(token) || null;

          if (
            account &&
            jwtParsed &&
            moment(jwtParsed.exp * 1000).diff(moment(), 'minutes') < 5
          ) {
            const acquireGraphToken = await acquireToken(
              { account, ...REQUESTS.LOGIN },
              useRedirectFlow,
            );

            const appToken = await acquireToken(
              { account, ...REQUESTS.ACQUIRE_TOKEN, forceRefresh: true },
              useRedirectFlow,
            );

            if (acquireGraphToken && appToken) {
              setStorage({
                key: STORAGE_KEYS.ID_TOKEN,
                val: appToken.accessToken,
              });
              setStorage({
                key: STORAGE_KEYS.ACCESS_TOKEN,
                val: acquireGraphToken.accessToken,
              });

              setState({
                ...state,
                account: account[0],
                error: null,
              });
            }
          }
        } catch (error) {
          if (error) {
            const errorMessage = error.errorMessage
              ? error.errorMessage
              : 'Unable to acquire access token.';

            setState({
              ...state,
              error: errorMessage,
            });
          }
        }
      }, 120000);

      setState(oldState => ({
        ...oldState,
        silentSignedIn: true,
        interval,
      }));

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, []);

    return <PassedComponent {...wrappedProps} {...state} />;
  };
  return React.memo(WrappedComponent);
};

export default withAuthProvider;
