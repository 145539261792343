import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getOPUsRequest,
  setOPUActive,
  opusSelector,
  opuActiveSelector,
} from '../slices/opu';

import { setStorage } from '../services/storage';

import { STORAGE_KEYS } from '../constants/storageKeys';

const useOPU = () => {
  const opus = useSelector(opusSelector);
  const opuActivated = useSelector(opuActiveSelector);

  const opuActions = useMemo(() => {
    if (opuActivated?.permissions?.actions) {
      return opuActivated?.permissions?.actions;
    }

    return [];
  }, [opuActivated]);

  const dispatch = useDispatch();

  const onGetOPUs = useCallback(params => {
    dispatch(getOPUsRequest(params));
  }, []);

  const onSetOPUActivated = useCallback(opu => {
    setStorage({
      key: STORAGE_KEYS.OPU_ACTIVATED,
      val: opu,
    });
    dispatch(setOPUActive(opu));
  }, []);

  return {
    opus,
    opuActivated,
    opuActions,
    onGetOPUs,
    onSetOPUActivated,
  };
};

export default useOPU;
