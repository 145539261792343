import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaRegListAlt, FaCircle } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import NavDropdown from 'assets/images/icons/nav-dropdown.svg';

import { LANDING_PATH, REPORT_LISTING_PATH } from 'constants/routePaths';

import useQuery from 'hooks/useQuery';
import { reportSelector } from 'slices/report';
import SubMenus from './SubMenus';

import './styles.scss';

export const HEADER_HEIGHT = 56;
export const LEFT_SIDEBAR = 60;
export const SIDEBAR_WIDTH_EXPAND = 240;
export const SIDEBAR = 120;
export const EXPAND_BUTTON = 24;

const OPUMenu = ({
  isSideBarExpand,
  opus,
  opuActivated,
  onSetOPUActivated,
}) => {
  const toNotificationFormat = number => (number > 99 ? '99+' : number);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const report = useSelector(reportSelector());

  const opuName = decodeURIComponent(query.get('opuName'));
  const [isOpen, setIsOpen] = useState(opuActivated?.name === opuName);

  useEffect(() => {
    // case: reload page or click a menu item that has children => will auto open children menu
    if (opuActivated?.isParent && !isEmpty(opuActivated?.children)) {
      setIsOpen(true);
    }
  }, [opuActivated, opuName]);

  const onSetOPUActivatedHandler = opu => {
    onSetOPUActivated(opu);

    let pathName = pathname;
    if (pathname !== LANDING_PATH) {
      // For case when in REPORT_LISTING_PATH and click it seft
      if (
        opuActivated?.name === opu?.name &&
        pathname?.includes(REPORT_LISTING_PATH)
      ) {
        pathName = pathname;
      }
      // For case when in other PATH and click it seft => will back to correct selected category
      else if (
        !pathname?.includes(REPORT_LISTING_PATH) &&
        report?.data?.opu?.id === opuActivated?.id &&
        report?.data?.category?.id
      ) {
        pathName = `${REPORT_LISTING_PATH}/${report?.data?.category?.id}`;
      }
      // For other case, will back to default REPORT_LISTING_PATH
      else {
        pathName = REPORT_LISTING_PATH;
      }
    }

    navigate({
      pathname: pathName,
      search: `?opuName=${encodeURIComponent(opu?.name)}`,
    });

    // case: a menu item is a parent and dont have children
    if (opu?.isParent && isEmpty(opu.children)) {
      setIsOpen(false);
    }
  };

  const genDotIcon = opu =>
    opu?.pendingActionCount ? (
      <div className="pvda-opu-menu__item-notifi">
        {toNotificationFormat(opu?.pendingActionCount)}
      </div>
    ) : (
      <FaCircle color="#8879C9" size={8} />
    );

  const genShowArrowIcon = (isShowIcon, opu) =>
    isShowIcon && isSideBarExpand ? (
      <img
        className="pvda-opu-menu__icon-dropdown"
        src={NavDropdown}
        alt="nav"
      />
    ) : (
      genDotIcon(opu)
    );

  return (
    <nav
      className={`pvda-opu-menu ${
        isSideBarExpand ? 'pvda-opu-menu--expand' : 'pvda-opu-menu--collapse'
      }`}
    >
      <header className="pvda-opu-menu__header">
        <FaRegListAlt color="#7365B1" size={24} />
        <span>Report</span>
      </header>
      <ul className="pvda-opu-menu__body">
        {opus.map(opu => {
          const isShowIcon =
            !isEmpty(opu?.children) && opu?.id !== opuActivated?.id;
          return (
            <div key={opu?.id}>
              <li key={opu.id} className="pvda-opu-menu__item">
                <div
                  className={`pvda-opu-menu__item-content${
                    opu?.id === opuActivated?.id
                      ? ' pvda-opu-menu__item-content--activated'
                      : ''
                  }`}
                  role="presentation"
                  onClick={() => onSetOPUActivatedHandler(opu)}
                >
                  <span className="pvda-opu-menu__item-text">{opu.name}</span>

                  <div className="pvda-opu-menu__item-icon">
                    {genShowArrowIcon(isShowIcon, opu)}
                  </div>
                </div>
                <div className="pvda-opu-menu__tooltip">{opu.name}</div>
              </li>
              {!isEmpty(opu.children) && isOpen && (
                <SubMenus
                  rootPath=""
                  data={opu.children}
                  onSetOPUActivated={onSetOPUActivatedHandler}
                />
              )}
            </div>
          );
        })}
      </ul>
    </nav>
  );
};

OPUMenu.propTypes = {
  isSideBarExpand: PropTypes.bool,
  opus: PropTypes.array,
  opuActivated: PropTypes.object,
  onSetOPUActivated: PropTypes.func,
};

OPUMenu.defaultProps = {
  isSideBarExpand: false,
  opus: [],
  opuActivated: {},
  onSetOPUActivated() {},
};

export default memo(OPUMenu);
