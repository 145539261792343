/* eslint-disable consistent-return */
import { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { STATUS_CODE } from 'constants/statusCodes';
import { WHITE_LIST_API } from 'constants/whiteListAPI';
import { METHOD } from 'constants/apis';
import {
  EPIC_CATEGORY_REQUEST_ACCESS_PATH,
  ERROR_PATH,
} from 'constants/routePaths';
import { GET_TOTAL_UNREAD_MESSAGES_PATH } from 'constants/apiPaths';
import { GET_TOTAL_UNREAD_MESSAGES_FAILURE } from 'constants/messages';
import { setError } from 'slices/error';

import useAuth from './useAuth';

const useAxiosInterceptor = instance => {
  const navigate = useNavigate();
  const location = useLocation();

  const { onLoginWithRedirect } = useAuth();

  const dispatch = useDispatch();

  const onSetError = useCallback(error => {
    dispatch(setError(error));
    navigate(ERROR_PATH);
  }, []);

  // Add a request interceptor
  const reqInterceptor = instance.interceptors.request.use(
    // Do something before request is sent
    config => config,
    // Do something with request error
    error => Promise.reject(error),
  );
  // Add a response interceptor
  const resInterceptor = instance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response => response,
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log('err.response.data', err.response.data);
      // console.log('err.response.status', err.response.status);
      // console.log('err.response.headers', err.response.headers);

      // Cancel request
      if (axios.isCancel(error)) {
        return;
      }

      const { status = 500, config = {}, data = {} } = error?.response || {};
      switch (status) {
        case STATUS_CODE.UNAUTHORIZED:
          onLoginWithRedirect(true); // isTokenExpired is true
          break;
        case STATUS_CODE.NOT_FOUND: {
          let NOT_FOUND_PATH = '/not-found';

          if (data?.Data?.[0]?.Message) {
            NOT_FOUND_PATH = `${NOT_FOUND_PATH}?messageError=${data.Data[0].Message}`;
          }

          navigate(NOT_FOUND_PATH);
          break;
        }
        case STATUS_CODE.BAD_REQUEST:
        case STATUS_CODE.METHOD_NOT_ALLOWED: {
          const url = error?.config?.url || '';
          if (
            url?.includes(GET_TOTAL_UNREAD_MESSAGES_PATH) &&
            status === STATUS_CODE.BAD_REQUEST
          ) {
            dispatch(
              setError({
                response: {
                  data: {
                    StatusCode: status,
                    Data: [
                      {
                        Message: GET_TOTAL_UNREAD_MESSAGES_FAILURE,
                      },
                    ],
                  },
                },
              }),
            );
            break;
          } else if (
            config.method === METHOD.GET ||
            [EPIC_CATEGORY_REQUEST_ACCESS_PATH].includes(location.pathname)
          ) {
            if (!isEmpty(data?.Data) && !data?.Data.hasAccessReport) {
              onSetError({
                response: {
                  data: {
                    StatusCode: 403,
                    Data: [
                      {
                        Message:
                          'You do not have permission to access this page!',
                      },
                    ],
                  },
                },
              });
              break;
            }
            onSetError(error);
          }
          break;
        }
        case STATUS_CODE.FORBIDDEN:
        case STATUS_CODE.SERVER_ERROR:
        default: {
          const url = error?.config?.url || '';
          let whiteListIndex = WHITE_LIST_API.length - 1;

          for (; whiteListIndex >= 0; whiteListIndex--) {
            if (url.includes(WHITE_LIST_API[whiteListIndex])) {
              break;
            }
          }

          const inValidWhiteList = whiteListIndex === -1;
          inValidWhiteList && onSetError(error);

          break;
        }
      }

      return Promise.reject(error);
    },
  );

  useEffect(
    () => () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    },
    [reqInterceptor, resInterceptor],
  );
};

export default useAxiosInterceptor;
